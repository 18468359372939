html,
body {
	height: 100%;
}

body {
	margin: 0;
	overflow: hidden;
	background-color: #546e7a;
}

#score {
	font-family: monospace;
	font-size: 1rem;
	line-height: 1.25;
	color: #fff;
	text-transform: uppercase;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: space-between;
	padding: 0.5rem 1rem;
	border-bottom: 1px solid #fff;
}

canvas {
	display: block;
}

footer {
	color: #fff;
	padding: 1rem;
	border-top: 1px solid #fff;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
}

social-links {
	width: 27.875rem;
	display: block;
}
